/** Wrapper **/
.wrapper {
  flex: 1 auto;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 70px;
  height: 100vh;
}
.wrapper .map {
  height: calc(100vh - 174px);
  transition: all 0.4s;
  background: #ffffff;
  overflow: hidden;
  margin: 0px 15px 15px 15px;
  border-radius: 20px;
}
.wrapper .map > div {
  width: 100%;
  height: 100% !important;
}
.wrapper .map-close {
  display: none;
}

/** Fonts **/
h1 {
  margin-top: 0px;
  color: var(--primary-color);
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 6px;
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .wrapper {
    flex-direction: row;
  }
  .wrapper .map {
    width: calc(100% - 40px);
    height: calc(100vh - 70px);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    margin: 0;
    border-radius: 0;
  }
  .wrapper .map-close {
    width: calc(100% - 50%);
    display: block;
  }
}
