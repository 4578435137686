.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../../../public/assets/img/loader.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 400px;
  display: inline-block;
}

@media (max-width: 576px) {
  .map-marker {
    width: 92px;
    height: 102px;
  }
}
