:root {
  --temperature: rgb(247 29 66 / 100%);
  --temperature-15: rgb(247 29 66 / 15%);
  --waves: rgb(29 87 247 / 100%);
  --waves-15: rgb(29 87 247 / 15%);
  --meteo: rgb(73 70 71);
  --meteo-15: rgb(73 70 71 / 15%);
  --goodHeavens: #494647;
  --sun: #494647;
  --tide: rgb(29 195 247 / 100%);
  --tide-15: rgb(29 195 247 / 15%);
  --air: rgb(247 147 29 / 100%);
  --air-15: rgb(247 147 29 / 15%);
}
.sidebar {
  position: relative;
  background: #fff;
  width: auto;
  height: 80px;
  box-shadow: rgba(27, 46, 94, 0.05) 0px 0px 24px 0px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.sidebar-close {
  width: 100%;
  height: 100vh;
}
.sidebar-close .sidebar-button {
  transform: rotate(-270deg);
}
.sidebar-button {
  width: 40px;
  height: 40px;
  z-index: 99;
  left: calc(50% - 25px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: rotate(270deg);
}
.sidebar-close .sidebar-content {
  opacity: 1;
  display: block;
}
.sidebar-content {
  display: none;
  opacity: 0;
  height: calc(100% - 83px);
  padding: 16px;
}
.sidebar-content .ubication {
  color: var(--charcoal-gray);
  margin-bottom: 6px;
}
.sidebar-content .date {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.sidebar-content .date span {
  background: var(--light-gray);
  padding: 5px 7px;
  margin-right: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12.6px;
}
.sidebar-content .date span img {
  margin-right: 10px;
}
.list {
  margin-top: 30px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.list-item {
  border-bottom: 1px solid var(--medium-gray);
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.list .list-col-2:last-child .list-item:last-child {
  border-bottom: 0px;
}
.list-col-2 {
  display: block;
}
.list-col-2 .list-item {
  width: 100%;
}
.list-col-2 .list-item:nth-child(1) {
  margin-right: 0%;
}
.list-item-icon {
  background: var(--light-gray);
  margin: 10px 10px 10px 0px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-item-icon img {
  width: 70%;
  height: auto;
}
.list-item-content {
  width: calc(100% - 60px);
  display: flex;
  align-items: start;
  align-content: stretch;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 10px 0px;
}
.list-item-col {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  flex-direction: row;
  height: 80%;
  width: 100%;
  flex: 1 0 0%;
}
.list-tide .list-item-col .text {
  display: inline-block;
  margin: 0px 10px 7px 11px;
}
.list-item-col:first-child {
  border-left: 0;
}
.col-inline {
  align-items: start;
  padding: 7px 0px;
}
.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.title {
  font-weight: 700;
  display: block;
  text-align: center;
  margin: 3px 10px 3px 10px;
}
.list-tide .list-item-col .title,
.list-tide .list-item-col .description,
.list-tide .list-item-col .text {
  width: 31%;
  text-align: left;
  margin-left: 0px;
  margin-right: 2%;
}
.inline .title {
  margin-bottom: 0px;
}
.text {
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  display: block;
}
.text img {
  height: 16px;
}
.description {
  font-size: 16px;
  color: var(--charcoal-gray);
  font-style: italic;
}
.units {
  font-size: 20px;
}
.list-item-temperature {
  background-color: var(--temperature-15);
}
.list-item-waves {
  background-color: var(--waves-15);
}
.list-item-tide {
  background-color: var(--tide-15);
}
.list-item-air {
  background-color: var(--air-15);
}
.dots {
  height: 40px;
  width: auto;
  text-align: center;
}
.section-brand {
  display: flex;
  margin: 5px 0px 5px 0px;
}
.section-brand img {
  height: 39px;
  margin-right: 10px;
}
.sidebar-buttons {
  text-align: right;
}
.sidebar-buttons button {
  padding: calc(0.815rem + 1px) calc(1.5rem + 1px);
  color: var(--secondary-color);
  background-color: var(--medium-gray);
  border: transparent;
  font-weight: 600;
  margin-left: 10px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.sidebar-buttons button:hover {
  background-color: var(--dark-gray);
}
.sidebar-buttons .button-map,
.sidebar-buttons .button-dades {
  background: var(--primary-color);
  color: #fff;
}
.sidebar-message {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 10%;
}
.sidebar-message > p {
  font-size: 30px;
}
.sidebar-message > p img {
  height: 40px;
}
.sidebar-message > img {
  width: 90%;
  height: auto;
}

.list::-webkit-scrollbar {
  height: 12px;
}
.list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.skeleton-item {
  background-color: var(--light-gray);
  color: var(--light-gray);
  border-radius: 4px;
  animation: skeleton-animation 1.5s infinite linear;
}
.sidebar-hidden {
  display: none;
}
/* Sidebar Tabs */
.sidebar-tabs {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 12px;
}

.tab-button {
  padding: 0px 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s;
  position: relative;
}

.tab-button:hover {
  background-color: #f0f0f0;
}

.tab-button.active {
  font-weight: bold;
  color: #f7931d;
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #f7931d;
}

@keyframes skeleton-animation {
  0% {
    background-color: var(--light-gray);
    color: var(--light-gray);
  }
  50% {
    background-color: var(--medium-gray);
    color: var(--medium-gray);
  }
  100% {
    background-color: var(--light-gray);
    color: var(--light-gray);
  }
}

@media (max-width: 576px) {
  .sidebar-content {
    padding-top: 0px;
    padding-left: 12px;
  }
}

@media (min-width: 768px) {
  .list-item {
    border: 1px solid var(--medium-gray);
    margin-bottom: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .list .list-col-2:last-child .list-item:last-child {
    border-bottom: 2px solid var(--medium-gray);
  }
  .list-item-col {
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid var(--medium-gray);
    min-height: 100px;
    width: auto;
  }
  .list-item-content {
    flex-direction: row;
    align-items: center;
    padding: 0px;
  }
  .list-item-icon {
    margin: 7px;
  }
  .text img {
    height: 22px;
  }
  .list-tide .list-item-col .text {
    margin: 0px;
  }
  .inline {
    padding-left: 10px;
    margin-bottom: 5px;
  }
  .text {
    font-size: 22px;
    line-height: 21px;
  }
  .sidebar-message > img {
    width: 400px;
    height: auto;
  }
  .inline .title {
    min-width: 181px;
    text-align: left;
  }
  .list-tide .list-item-col .title,
  .list-tide .list-item-col .description,
  .list-tide .list-item-col .text {
    width: auto;
    text-align: center;
    margin-left: inherit;
    margin-right: inherit;
  }
  .sidebar-content .date span {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .sidebar {
    height: auto;
    width: 40px;
    border-right: 5px solid var(--light-gray);
  }
  .sidebar-close {
    width: 100%;
    max-width: 80%;
    height: auto;
    max-height: none;
  }
  .sidebar-content {
    padding: 25px 9px 25px 15px;
  }
  .sidebar-button {
    background: white;
    width: 50px;
    height: 50px;
    z-index: 99;
    right: -25px;
    position: absolute;
    top: 12%;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--light-gray);
    box-shadow: rgba(27, 46, 94, 0.05) 0px 0px 24px 0px;
    transform: rotate(180deg);
    left: auto;
  }
  .sidebar-close .sidebar-button {
    transform: rotate(0);
  }
  .list {
    margin-top: 20px;
    height: calc(100vh - 35vh);
  }
  .list-item-icon {
    margin: 7px;
    width: 80px;
    height: 80px;
  }
  .list-item-content {
    width: calc(100% - 80px);
  }
  .list-item-col {
    min-height: 100px;
  }
  .title {
    margin: 0px 0px 7px 0px;
  }
  .text {
    font-size: 22px;
    line-height: 25px;
  }
  .list-tide .title {
    margin: 0px !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-close {
    width: 100%;
    max-width: 75%;
    height: auto;
    max-height: none;
  }
  .list-item-icon {
    margin: 5px;
    width: 90px;
    height: 90px;
  }
  .list-item-content {
    width: calc(100% - 90px);
  }
  .list-item-col {
    min-height: 100px;
  }
  .list-tide .title {
    margin: 0px !important;
  }
  .text {
    font-size: 22px;
    line-height: 25px;
  }
}

@media (min-width: 1400px) {
  .sidebar-close {
    width: 100%;
    max-width: 70%;
    height: auto;
    max-height: none;
  }
  .list-col-2 {
    display: flex;
  }
  .list-col-2 .list-item {
    width: 49%;
  }
  .list-col-2 .list-item:nth-child(1) {
    margin-right: 2%;
  }
  .list-item-icon {
    margin: 10px;
    width: 100px;
    height: 70px;
  }
  .list-item-content {
    width: calc(100% - 100px);
  }
  .list-item-col {
    min-height: 80px;
  }
  .list-tide .title {
    margin: 0px !important;
  }
  .text {
    font-size: 22px;
    line-height: 25px;
  }
}

@media screen and (min-width: 2400px) {
  .sidebar-tabs {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 8px;
  }

  .tab-button {
    padding: 8px 16px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 30px;
    transition: color 0.3s;
    position: relative;
  }
}
